import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../styles';
import { Container } from '@material-ui/core'
import GenericBanner from '../components/GenericBanner'
import Footer from '../components/Footer'

const useStyles = makeStyles(theme => { return {
  ...styles(theme)
}})

function PrivacyPage() {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.root}>
        <GenericBanner label="PRIVACY POLICY" />
        <Container maxWidth="md" className={classes.lightContainer}>

<p className="c6"><span><b><center>PRIVACY POLICY</center></b></span></p>
<p className="c2"><span className="c1">This Privacy Policy describes the data we collect when you use our website, 
        subscriptions, and software (&ldquo;Services&rdquo;), and what we do with your data.</span></p>
<p className="c2"><span><b>1.0. Updates to the Policy. </b></span><span className="c1">We may update or modify this Policy at
        any time without notice, although we will make reasonable efforts to inform you of any updates we consider 
        substantial, at our discretion. Your use of the Services following any update or
        modification constitutes your agreement with the Policy as updated or modified.</span></p>
<p className="c2"><span><b>1.1. The data we collect.</b></span><span className="c1">&nbsp;When you use the Services,
        we may collect your IP address and other usage data such as your browser type, time you spend using the Services,
        and other statistics, for the purposes of analyzing usage and ensuring the Services are operating
        correctly.  When you create an account with us, you share with us your email address, a username and an account password.
        We may ask for other information such as your phone number or name in order to provide an account profile or 
        related services such as multifactor authentication.  We also store the data,
        information, or actions you put into our game software&rsquo;s online components, such as what you say or build
        in the game, and these may be visible to other users or difficult to remove.</span></p>
<p className="c2"><span><b>1.2. Sharing your data.</b></span><span className="c1">&nbsp;We do not share your data 
        with anyone, except for the purposes of providing, operating and maintaining the Services.  For instance, when we rely 
        on a third-party such as Stripe for payment processing, your email address and other necessary information will 
        be shared with them and stored on their servers for the purpose of providing their service.  In these cases, consult 
        the Privacy Policy of the third-party service to see how they use your data.</span></p>
<p className="c2"><span><b>1.3. Storage. </b></span><span className="c1">We store the information we collect through the
        Services on our systems or licensed systems, such as Amazon Web Services, and use it for the purposes described
        in this Policy.</span></p>
<p className="c2"><span><b>1.4. Payment Processing. </b></span><span className="c1">We use Stripe, a third-party service, 
        for payment processing.  You can find Stripe's Privacy Policy and Terms of Service at stripe.com.</span></p>
<p className="c2"><span><b>1.5. Cookies.</b></span><span className="c1">&nbsp;We use cookies and related technologies such
        as browser storage in order to provide the Services; for example, to store your website preferences or recognize
        you without requiring your password. &nbsp;In addition, third-parties we rely on such as Google Analytics and
        Stripe may use cookies and related technologies as part of their services.</span></p>
<p className="c2"><span><b>1.6. Email. </b></span><span className="c1">We may send you email when necessary to notify you
        of information we believe is important for the operation of the Services, or when you request an email during an 
        action such as resetting your password.  Your email address and other relevant information may be used with or 
        stored on third-party mailing services such as MailChimp for the purposes of helping us send email and manage 
        unsubscribe lists.  You may opt in to our mailing list. If you opt in, we may send you email for marketing 
        purposes. </span></p>
<p className="c2"><span><b>1.7. Analytics and Third-Parties. </b></span><span className="c1">We may use a variety of
        third-party services, including analytics tools such as Google Analytics, to help provide the Services or to 
        measure or improve the use of the Services. These third-party service providers may use tracking technologies to 
        collect information sent by your device, and may share such information with Jellygon.</span></p>
<p className="c2"><span><b>1.8. Security. </b></span><span className="c1">The security of your Personal Information is 
        important to us, but remember that no method of transmission over the Internet, or method of electronic storage, 
        is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot 
        guarantee its absolute security.</span></p>
        </Container>
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default PrivacyPage